.MenuItem {
  color: black;
}
.MuiBottomNavigationAction.Mui-selected {
  color: #2979ff !important;
}

.MuiLinearProgress-root {
  height: 10px !important;
  background-color: Gainsboro !important;
  border-radius: 10px;
}

.Mui-disabled {
  background-color: Gainsboro !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: rgb(157, 212, 157) !important;
}


/* customize right scroll bar */
/* width */
::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(207, 207, 207);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
